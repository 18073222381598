@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.form {
  width: 100%;
  label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  input {
    background-color: transparent;
    color: variable.$blackColor;
    font-size: variable.$size14;
    flex: 1;
    border: 1px solid variable.$borderColor;
    height: 32px;
    text-indent: 5px;
    border-radius: 5px;
    width: 100%;
    min-width: 100px;
    box-shadow: none;
    outline: none;
    margin-bottom: 15px;
    font-weight: bold;
    &::placeholder {
      color: #bcbcbc;
    }
  }
  .action {
    text-align: center;
    padding-right: 35px;
    button {
      display: inline-block;
    }
  }
}
@include mixin.mediaDesktopSize() {
  .form {
    .action {
      padding-right: 0;
      margin-top: 20px;
    }
  }
}
