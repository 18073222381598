@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  padding: 60px 15px;
  margin-top: 30px;
  border-top: 1px solid rgba(220, 207, 190, 0.5);
  .title {
    margin-bottom: 20px;
    &.left {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
  }
}
