@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.assiette {
  padding: 45px 0;
  background-color: #ffffff;
  .entryTitle {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .feature {
    width: 36%;
    max-width: 355px;
  }
  .content {
    width: 60%;
    > div {
      border-radius: 20px;
      padding: 0px 30px;
    }
    .values {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 46%;
        margin-bottom: 18px;
        &:nth-child(3) {
          .val {
            > div {
              &:first-child {
                width: 70%;
              }
              &:last-child {
                width: 30%;
              }
            }
          }
        }
        &:nth-child(5) {
          .val {
            > div {
              &:first-child {
                width: 30%;
              }
              &:last-child {
                width: 70%;
              }
            }
          }
        }
        &:nth-child(6) {
          .val {
            > div {
              &:first-child {
                width: 40%;
              }
              &:last-child {
                width: 60%;
              }
            }
          }
        }
        .top {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          img {
            max-height: 30px;
            max-width: 50px;
            width: auto;
          }
          span {
            display: block;
            color: variable.$blackColor;
            font-size: variable.$size16;
            font-family: variable.$interFont;
            font-weight: 500;
            margin-left: 10px;
            flex: 1;
            text-align: left;
          }
        }
        .val {
          display: flex;
          width: 100%;
          border-top-left-radius: 8px;
          overflow: hidden;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 8px;
          > div {
            width: 50%;
            background-color: rgba(217, 217, 217, 0.5);
            font-size: variable.$size18;
            line-height: 48px;
            color: #333333;
            font-family: variable.$interFont;
            &.daily {
              background-color: #07bc0c;
              color: #ffffff;
            }
            &:nth-child(2) {
              border-bottom-right-radius: 10px;
            }
          }
          &.reverse {
            flex-direction: row-reverse;
            > div {
              &:nth-child(1) {
                border-bottom-right-radius: 10px;
              }
              &:nth-child(2) {
                border-bottom-right-radius: 0;
              }
            }
          }
        }
      }
    }
    .labels {
      margin-top: 65px;
      text-align: left;
      span {
        font-family: variable.$primaryFont;
        color: #333333;
        font-size: 14px;
        line-height: 25px;
        position: relative;
        margin-right: 30px;
        &::before {
          content: "";
          display: inline-block;
          display: inline-block;
          width: 40px;
          height: 25px;
          background-color: rgba(217, 217, 217, 0.502);
          vertical-align: middle;
          margin-right: 10px;
          border-radius: 3px;
        }
        &:last-child {
          &::before {
            background-color: #07bc0c;
          }
        }
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .assiette {
    .feature {
      width: 100%;
      margin: auto auto 50px;
    }
    .content {
      width: 100%;
    }
  }
}

@include mixin.mediaMobile() {
  .assiette {
    .feature {
      margin-bottom: 0;
    }
    .content {
      > div {
        padding: 30px 6vw;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .values {
        flex-direction: column;
        .item {
          width: 100%;
          &.it0 {
            order: 1;
          }
          &.it2 {
            order: 2;
          }
          &.it4 {
            order: 3;
          }
          &.it1 {
            order: 4;
          }
          &.it3 {
            order: 5;
          }
          &.it5 {
            order: 5;
          }
        }
      }
      .labels {
        text-align: left;
        span {
          display: block;
          margin: 0 0 10px;
        }
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .assiette {
    .feature {
      max-width: 280px;
    }
    .content {
      .values {
        .item {
          .val {
            > div {
              line-height: 40px;
            }
          }
        }
      }
      .labels {
        text-align: center;
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .assiette {
    .entryTitle {
      margin-top: 70px;
      br {
        display: none;
      }
    }
  }
}
