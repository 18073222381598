@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.title {
  font-size: 20px;
  font-family: variable.$secondaryFont;
  color: variable.$primaryDarkColor;
  margin: 30px 0;
}
.sponsoring {
  margin-top: 15px;
  border-radius: 10px;
  .text {
    font-family: variable.$primaryFont;
    font-weight: bold;
    font-size: variable.$size16;
    color: variable.$primaryColor;
    margin-bottom: 20px;
  }
  .whiteBox {
    width: 100%;
    margin: 0 auto;
    padding: 22px;
    background-color: variable.$lightWheat;
    color: variable.$primaryColor;
    border-radius: 10px;
    margin-bottom: 15px;
    .shareEmail {
      display: flex;
      gap: 15px;
      img {
        margin: auto;
        width: 35px;
        margin-top: 33px;
      }
    }
    label {
      color: variable.$blackColor;
      font-size: variable.$size14;
      font-family: variable.$interFont;
      font-weight: 600;
    }
    input {
      background-color: variable.$whiteColor;
      font-weight: normal;
      height: 45px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
@include mixin.mediaDesktopSize() {
  .sponsoring {
    .sponsorship {
      .whiteBox {
        padding: 20px 30px;
        .shareEmail {
          gap: 20px;
          form {
            > div {
              display: flex;
              gap: 15px;
              align-items: flex-start;
              input {
                margin-bottom: 0;
                height: 52px;
                + [class^="erreur-field"] {
                  margin-top: 10px;
                  margin-bottom: 0;
                }
              }
              > div:first-child {
                flex: 1;
              }
            }
          }
          img {
            margin: 40px 0 0;
          }
        }
      }
      button span {
        font-size: variable.$size24;
      }
    }
  }
}
