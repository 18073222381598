@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 40px 0;
  p {
    color: variable.$textColor;
    font-family: variable.$primaryFont;
    font-size: variable.$mediumSize;
    font-weight: bolder;
    margin: 3px 0;
  }
}
