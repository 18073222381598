@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.tags {
  background-color: variable.$lightWheat;
  padding: 30px 10px 50px;
  overflow: hidden;
  .slider {
    max-width: calc(100% - 40px);
  }
  [class*="slick-list"] {
    overflow: visible;
  }
  [class*="slick-slide"] {
    visibility: visible;
  }
}
.tag {
  padding: 0 10px;
  .img {
    padding-top: 83%;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }
  .content {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    text-align: center;
    .icon {
      margin: auto;
      width: 28px;
      height: 28px;
      filter: brightness(0) invert(1);
      object-fit: contain;
    }
    .name {
      font-family: variable.$defaultFont;
      font-size: variable.$size24;
      text-transform: uppercase;
      margin-top: 5px;
      color: variable.$whiteColor;
    }
    .caption {
      min-height: 40px;
      font-family: variable.$interFont;
      font-weight: 400;
      font-size: variable.$size14;
      color: variable.$whiteColor;
    }
  }
}
@include mixin.mediaTabletSize() {
  .tags {
    border-top: 0.5px solid #eee9e4;
    padding-top: 50px;
  }
}
@include mixin.mediaDesktopSize() {
  .tags {
    .slider {
      max-width: 100%;
    }
  }
}
