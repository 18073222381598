@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.descouverButton {
  padding-bottom: 30px;
  display: none;
  a {
    margin: auto;
  }
}
.wrapper {
  padding-bottom: 50px;
  padding-top: 10px;
  .title {
    margin-bottom: 0;
  }
  .subtitle {
    padding: 0 6%;
    text-align: center;
  }
  .items {
    margin-top: 10px;
    margin-left: -30px;
    [class*="slick-next"],
    [class*="slick-prev"] {
      background-image: none !important;
      background-color: variable.$whiteColor;
      left: auto;
      right: 0;
      width: 36px;
      height: 50px;
      border-radius: 5px 0 0 5px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      &:before {
        content: "";
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
        display: inline-block;
        opacity: 1;
        transform: rotate(-45deg);
      }
    }
    [class*="slick-prev"] {
      left: 30px;
      right: auto;
      border-radius: 0 5px 5px 0;
      &:before {
        transform: rotate(135deg);
      }
    }
    [class*="slick-dots"] {
      padding-left: 30px;
    }
    .item {
      padding-top: 150%;
      position: relative;
      > div {
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: #000;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 15px 30px;
        z-index: 10;
        transition: all 0.25s ease-in-out !important;
      }
      .productName {
        font-size: 4.1vw;
        color: variable.$whiteColor;
        font-family: variable.$defaultFont;
        text-transform: uppercase;
        min-height: 51px;
      }
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;
        margin: auto auto 0;
        padding: 0 10px 5px;
      }
    }
  }
}

@include mixin.mediaTabletSize() {
  .descouverButton {
    display: block;
  }
  .wrapper {
    .title {
      &::after,
      &::before {
        display: none;
      }
    }
    .items {
      position: relative;
      margin-top: 35px;
      .item {
        .bottom {
          padding-bottom: 20px;
        }
        .productName {
          font-size: variable.$size22;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    .items {
      .item {
        .bottom {
          padding: 0 10px 40px;
          gap: 40px;
          max-width: 300px;
        }
        .productName {
          font-size: variable.$size20;
        }
      }
    }
  }
}
