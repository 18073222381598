@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.offres {
  padding: 55px 0 45px;
  border-top: 2px solid #ccc;
  .item {
    width: 50%;
    margin: 0 auto 40px;
    .label {
      font-family: variable.$titlesFont;
      color: variable.$primaryColor;
      font-size: 6vw;
      text-transform: uppercase;
    }
    .feat {
      img {
        max-height: 68px;
        width: auto;
        margin: 0 auto 20px;
      }
    }
  }
  .action {
    text-align: center;
    margin-bottom: 60px;
    a {
      display: inline-block;
      width: auto;
      padding: 0 30px;
      font-size: variable.$size26;
      line-height: 55px;
      min-width: 250px;
    }
  }
}

@include mixin.mediaSmallTabletSize() {
  .offres {
    .item {
      width: 33%;
      max-width: 240px;
      .label {
        font-size: variable.$bigSize;
      }
    }
  }
}
