@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.who {
  padding: 35px 0 35px;
  border-top: 2px solid #ccc;
  .item {
    width: 28%;
    .label {
      font-family: variable.$titlesFont;
      display: block;
      text-align: center;
      color: variable.$primaryColor;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .feat {
      margin: 0 auto 30px;
    }
    .text {
      color: #191919;
      font-size: 17px;
      line-height: 21px;
      font-family: variable.$primaryFont;
    }
    .action {
      margin-top: 20px;
      a {
        text-transform: uppercase;
      }
    }
  }
}

@include mixin.mediaTablet() {
  .who {
    .item {
      width: 31%;
      .text {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
}

@include mixin.mediaMobile() {
  .who {
    .item {
      width: 100%;
      max-width: 70%;
      margin: 0 auto 40px;
      .feat {
        margin: 0 auto 15px;
      }
      .label {
        font-size: 6vw;
      }
      .text {
        font-size: 3.5vw;
        line-height: 5vw;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .who {
    .item {
      max-width: 75%;
      .label {
        font-size: 8vw;
      }
      .text {
        font-size: 4.5vw;
        line-height: 6vw;
      }
    }
  }
}
