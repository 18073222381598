@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  margin-top: 45px;
  margin-bottom: 50px;
  padding-top: 40px;
  border-top: 1px solid rgba(220, 207, 190, 0.5);
  .title {
    margin-bottom: 55px;
  }
  .items {
    [class*="slick-slide"] {
      visibility: visible;
    }
    .item {
      text-align: center;
      padding: 0 25px;
      .icon {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .label {
        font-family: variable.$defaultFont;
        color: variable.$primaryDarkerColor;
        text-transform: uppercase;
      }
      .caption {
        font-family: variable.$interFont;
        font-size: variable.$size14;
        line-height: 22px;
        color: variable.$blackColor;
        br {
          display: none;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    padding-top: 0;
    border-top: none;
    margin-bottom: 75px;
  }
}
