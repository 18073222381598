@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.bar {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: variable.$lightWheat;
  padding: 9px 0;
  z-index: 99;
  opacity: 0;
  &.isScrolled {
    opacity: 1;
  }
  .boxReduction {
    display: flex;
    align-items: center;
    p {
      font-family: variable.$defaultFont;
      color: variable.$textColor;
      text-align: left;
      letter-spacing: 0;
      font-size: variable.$size16;
      line-height: variable.$size19;
      text-transform: uppercase;
      margin: 0;
      strong {
        color: variable.$primaryDarkerColor;
        font-family: variable.$titlesFont;
      }
      em {
        display: inline-block;
        color: #ffffff;
        text-align: center;
        font-family: variable.$titlesFont;
        background-color: variable.$primaryDarkerColor;
        width: 42px;
        height: 22px;
        border-radius: 4px;
        position: relative;
        transform: rotate(-9deg);
        margin: 0 2px;
        &::before {
          content: "";
          background-color: #f7f4e6;
          width: 8px;
          height: 8px;
          border-radius: 25px;
          position: absolute;
          left: -4px;
          top: 7px;
        }
        &::after {
          content: "";
          background-color: #f7f4e6;
          width: 8px;
          height: 8px;
          border-radius: 25px;
          position: absolute;
          right: -4px;
          top: 7px;
        }
      }
    }
  }
  .boxAction {
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@include mixin.mediaSmallTabletSize() {
  .bar {
    .boxReduction {
      p {
        font-size: variable.$size20;
        line-height: variable.$size26;
        width: 100%;
        em {
          width: 58px;
          height: 28px;
          margin: 0 5px;
          &::after,
          &::before {
            top: 10px;
          }
        }
      }
    }
    .boxAction {
      width: auto;
      justify-content: left;
      padding-left: 20px;
    }
  }
}
@include mixin.mediaTabletSize() {
  .bar {
    .boxReduction {
      p {
        text-align: right;
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .bar {
    .boxReduction {
      p {
        font-size: variable.$size24;
      }
    }
  }
}
