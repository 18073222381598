@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.mangez {
  padding: 50px 10px 4px;
  .content {
    position: relative;
    margin-top: 60px;
    .container {
      display: flex;
      gap: 30px;
    }
    .feature {
      position: absolute;
      left: 0;
      right: 0;
      height: 290px;
      top: 100px;
    }
    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      &:first-child {
        text-align: right;
        .indice {
          &:before {
            background-color: variable.$orangeColor;
            content: "-";
          }
        }
        .colTitle {
          color: variable.$orangeColor;
        }
      }
      .colTitle {
        color: variable.$primarylightColor;
        text-align: center;
        font-family: variable.$defaultFont;
        font-size: variable.$size22;
        line-height: 26px;
        order: 1;
        margin-bottom: 25px;
      }
      .row {
        order: 3;
        margin-bottom: 10px;
        span {
          font-family: variable.$defaultFont;
          display: block;
          color: variable.$blackColor;
          font-size: variable.$size22;
        }
        p,
        li {
          color: variable.$blackColor;
          font-family: variable.$interFont;
          font-size: variable.$size14;
          line-height: 21px;
        }
        ul {
          padding-left: 0;
          li {
            position: relative;
            display: flex;
            margin-bottom: 3px;
            &:before {
              content: "";
              position: relative;
              display: inline-block;
              width: 18px;
              min-width: 18px;
              height: 18px;
              margin-right: 6px;
              margin-top: 1px;
              background-size: contain;
              background-image: url("/static/images/icons/check-circle-ulist.svg");
            }
          }
        }
      }
      .indice {
        order: 2;
        background-color: #f8efe0;
        padding: 18px 5px;
        text-align: center;
        border-radius: 10px;
        max-width: 320px;
        position: relative;
        margin: 0 auto;
        margin-bottom: 220px;
        min-height: 100px;
        &::before {
          content: "+";
          display: block;
          width: 25px;
          height: 25px;
          font-size: variable.$size22;
          line-height: 22px;
          color: variable.$whiteColor;
          background-color: variable.$primarylightColor;
          border-radius: 50px;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translate(-50%, -50%);
        }
        p {
          color: variable.$blackColor;
          font-size: variable.$size16;
          margin-bottom: 0;
          font-family: variable.$interFont;
          line-height: 21px;
        }
      }
    }
  }
}

@include mixin.mediaDesktopSize() {
  .mangez {
    .entryTitle {
      br {
        display: none;
      }
    }
    .content {
      .feature {
        height: 400px;
        top: 35px;
      }
      .container {
        gap: 160px;
        position: relative;
      }
      .col {
        &:first-child {
          .colTitle {
            text-align: right;
          }
          .indice {
            margin-right: 0;
          }
          .row {
            padding-right: 80px;
            &:nth-child(3),
            &:nth-child(4) {
              padding-right: 120px;
            }
          }
        }
        &:last-child {
          .colTitle {
            text-align: left;
          }
          .indice {
            margin-left: 0;
          }
          section:last-of-type {
            margin: auto 0 35px;
          }
          .row {
            padding-left: 100px;
            ul {
              margin-top: 20px;
              padding-left: 20px;
              li {
                margin-bottom: 10px;
              }
            }
          }
        }
        .colTitle {
          font-size: variable.$size28;
          margin-bottom: 70px;
        }
        section:last-of-type {
          margin-bottom: 50px;
        }
        .row {
          order: 2;
          margin-bottom: 35px;
          span {
            font-size: variable.$size26;
          }
          p,
          li {
            font-size: variable.$size16;
          }
        }
        .indice {
          order: 3;
          margin: auto auto 0;
          min-height: auto;
        }
      }
    }
  }
}
