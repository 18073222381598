@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  border: 1px solid variable.$primaryDarkerColor;
  border-radius: 10px;
  background-color: variable.$whiteColor;
  max-width: 370px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:first-child {
    .box {
      .label {
        margin-bottom: 20px;
        strong {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            right: 0;
            height: 6.5px;
            mask-image: url("/static/images/icons/underlines-2.png");
            mask-size: contain;
            background-color: variable.$orangeColor;
            mask-repeat: no-repeat;
          }
        }
      }
    }
  }
  .box {
    height: 100%;
    .img {
      height: 150px;
      background-color: variable.$borderColor;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .title {
      font-family: variable.$defaultFont;
      font-size: variable.$size24;
      text-transform: uppercase;
      color: variable.$primaryDarkerColor;
      margin-block: 20px 15px;
      display: block;
    }
    .amount {
      white-space: nowrap;
      font-size: 31px;
      color: variable.$orangeColor;
      font-family: variable.$primaryFont;
      strong {
        font-size: 36px;
        font-family: variable.$defaultFont;
      }
    }
    .label {
      font-size: variable.$size14;
      font-family: variable.$interFont;
      font-weight: 400;
      color: variable.$primaryDarkerColor;
      strong {
        font-family: variable.$defaultFont;
        font-size: variable.$size20;
        em {
          color: variable.$orangeLightColor;
          font-size: 30px;
        }
      }
      em {
        text-transform: uppercase;
        color: variable.$orangeLightColor;
        font-size: 20px;
        font-family: variable.$defaultFont;
      }
    }
    h2 {
      font-family: variable.$defaultFont;
      font-size: 60px;
      color: variable.$orangeLightColor;
      em {
        font-size: 75% !important;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          left: 80%;
          bottom: 60%;
          height: 33px;
          width: 30px;
          mask-size: contain;
          mask-image: url("/static/images/icons/spreaded-lines.png");
          mask-repeat: no-repeat;
          background-color: currentColor;
          color: variable.$primaryColor;
          transform: scale(-1, 1);
        }
      }
    }
    pre {
      width: 20px;
      height: 20px;
      font-size: variable.$smallSize;
      background-color: variable.$primaryDarkerColor;
      color: variable.$whiteColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: variable.$defaultFont;
      text-transform: lowercase;
      border-radius: 50px;
      margin: 15px auto;
    }
  }
  .text {
    font-size: variable.$size13;
    font-family: variable.$interFont;
    color: variable.$primaryDarkerColor;
    margin-block: auto 0;
    padding: 25px 0 20px 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    &:first-child {
      .box {
        .label {
          margin-top: 25px;
        }
      }
    }
    .box {
      display: flex;
      .img {
        width: 110px;
        height: 100%;
      }
      .content {
        padding: 0 10px;
        flex: 1;
      }
    }
  }
}
