@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  padding-top: 45px;
  .title {
    margin-bottom: 25px;
    max-width: 345px;
    margin-inline: auto;
    br {
      display: none;
    }
  }
  .row {
    padding: 0 10px;
    .column {
      margin-bottom: 15px;
      .img {
        img {
          border-radius: 5px;
        }
      }
      .items {
        display: flex;
        gap: 15px;
        .item {
          background-color: variable.$lightWheat;
          border-radius: 5px;
          padding: 13px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          &.noCaption {
            flex: none;
            min-height: 113px;
          }
          .icon {
            margin-bottom: 5px;
            img {
              max-width: 75px;
              max-height: 40px;
              margin: auto;
            }
          }
          .label {
            font-size: variable.$size20;
            line-height: 20px;
            font-family: variable.$entryTitleFont;
            color: variable.$blackColor;
          }
          .caption {
            font-size: variable.$smallSize;
            color: variable.$blackColor;
            font-family: variable.$interFont;
            margin-top: 3px;
          }
        }
        > div {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }
    }
  }
  .action {
    margin-top: 28px;
    a {
      margin: auto;
    }
  }
}
@include mixin.mediaSmallTabletSize() {
  .wrapper {
    .title {
      max-width: 415px;
    }
  }
}
@include mixin.mediaTabletSize() {
  .wrapper {
    .title {
      max-width: 700px;
      br {
        display: block;
      }
    }
    .row {
      display: flex;
      gap: 20px;
      .column {
        margin: 0;
        flex: 1;
        .items {
          gap: 10px;
          > div {
            gap: 10px;
          }
        }
        .img {
          position: relative;
          height: 100%;
          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    padding-top: 80px;
    margin-bottom: 45px;
    .row {
      .column {
        .items {
          .item {
            padding: 40px 14px;
            .caption {
              font-size: variable.$smallSize;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
