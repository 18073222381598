@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;
.wrapper {
  padding: 61px 0;
  .title {
    margin-bottom: 50px;
  }
  .items {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;
    .item {
      width: calc(50% - 30px);
      text-align: center;
      .icon {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .label {
        font-family: variable.$defaultFont;
        color: variable.$primaryDarkerColor;
        text-transform: uppercase;
      }
      .caption {
        font-family: variable.$interFont;
        font-size: variable.$size14;
        line-height: 22px;
        color: variable.$blackColor;
        br {
          display: none;
        }
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .wrapper {
    .items {
      .item {
        width: calc(25% - 30px);
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    .items {
      gap: 18px;
      .item {
        width: calc(25% - 18px);
        .caption {
          br {
            display: block;
          }
        }
      }
    }
  }
}
