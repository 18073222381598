@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.videoContenaire {
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      position: relative;
    }
  }
  .btnClose {
    background: url("/static/images/icons/close.png") no-repeat;
    border: 0;
    display: block;
    height: 30px;
    position: absolute;
    right: 0;
    top: -27px;
    width: 30px;
    cursor: pointer;
    text-indent: -9999px;
  }
}

@include mixin.mediaTablet() {
  .videoContenaire {
    iframe {
      max-width: 630px !important;
      height: 329px !important;
    }
  }
}

@include mixin.mediaMobile() {
  .videoContenaire {
    iframe {
      height: 225px !important;
      max-width: 400px !important;
    }
  }
}

@include mixin.mediaSmallMobile() {
  .videoContenaire {
    iframe {
      height: 277px !important;
      max-width: 308px !important;
    }
  }
}
