@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  display: flex;
  justify-content: center;
  padding: 10px;
  img {
    display: block;
    max-width: 100%;
  }
}
