@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.goute {
  background-color: #daf5d9;
  padding: 50px 0 130px;
  position: relative;
  .feature {
    display: none;
  }
  .item {
    width: 29%;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 35px 10px 15px;
    .feat {
      margin: 0 auto 35px;
      max-height: 41px;
      max-width: 223px;
    }
    > p {
      font-family: variable.$primaryFont;
      color: #141414;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      min-height: 110px;
      margin-bottom: 15px;
      padding: 0 20px;
    }
    > span {
      font-family: variable.$primaryFontBold;
      color: #141414;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      padding-top: 15px;
      position: relative;
      letter-spacing: 1px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 45px;
        height: 2px;
        background-color: #000000;
        top: 0;
        left: 50%;
        margin-left: -22px;
      }
    }
  }
}

@include mixin.mediaTablet() {
  .goute {
    background-image: none !important;
    padding: 50px 0 60px;
    .item {
      width: 32%;
      > p {
        padding: 0 10px;
      }
    }
  }
}

@include mixin.mediaMobile() {
  .goute {
    padding-bottom: 0;
  }
  .slides {
    max-width: 80%;
    margin: 0 auto;
    padding: 0 35px 0;
    text-align: center;
    .item {
      > p {
        max-width: 260px;
        margin: 0 auto 15px;
        padding: 0;
      }
    }
  }
  .featMobile {
    max-width: 90%;
    margin-top: -5px;
  }
}

@include mixin.mediaSmallMobile() {
  .slides {
    max-width: 92%;
  }
}

@include mixin.mediaDesktopSize() {
  .goute {
    .feature {
      display: block;
      img:first-child {
        position: absolute;
        bottom: 0;
        left: 0;
      }
      img:nth-child(2) {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}
