@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrap {
  z-index: 3;
}
.image {
  display: block;
  z-index: 1;
  margin: 0 auto;
}

.head {
  padding: 115px 0 100px;
  position: relative;
  background-color: #f8f8fa;
  > div {
    max-width: 46%;
    background-color: variable.$whiteColor;
    margin-left: 3.4%;
    margin-right: auto;
    padding: 4px;
  }
  .fakeImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    z-index: 0;
  }
  h1 {
    font-family: variable.$titlesFont;
    display: block;
    text-align: center;
    color: #333333;
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 1px;
    margin-bottom: 25px;
    margin-top: 20px;
    strong {
      font-weight: bold;
    }
    span {
      display: block;
      font-size: variable.$size22;
      line-height: 27px;
      > em {
        font-style: normal;
        color: variable.$primaryColor;
        > em {
          display: inline-block;
          background-image: url("/static/images/home/tree-stars.png");
          width: 55px;
          height: 18px;
          background-size: 100% auto;
          background-repeat: no-repeat;
          margin: 0 3px;
          vertical-align: middle;
          font-size: 0;
          text-indent: -9999px;
        }
      }
    }
  }
  ul {
    max-width: 420px;
    margin: 0 auto 25px;
    li {
      font-family: variable.$primaryFont;
      display: block;
      position: relative;
      color: variable.$textColor;
      font-size: 17px;
      line-height: 24px;
      padding-left: 28px;
      background-repeat: no-repeat;
      background-position: left 3px;
      background-image: url("/static/images/icons/icon_tab_on.png");
      background-size: auto 20px;
      margin: 7px 0;
    }
  }
  a {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 26px;
    line-height: 55px;
    max-width: 185px;
    margin: 0 auto;
  }
  .trustpilot {
    background-color: #d4ead2;
    text-align: center;
    padding: 10px;
    margin-top: 35px;
    p,
    img {
      display: inline-block;
      vertical-align: middle;
    }
    p {
      font-family: variable.$primaryFont;
      color: variable.$textColor;
      font-size: 17px;
      margin: 0 8px;
      strong {
        font-family: variable.$primaryFontBold;
      }
    }
    img {
      height: 24px;
      width: auto;
      margin: 0 2px;
    }
  }
}

@media (min-width: 1300px) {
  .head {
    > div {
      max-width: 41%;
      margin-left: 8.4%;
    }
  }
}

@media (min-width: 1500px) {
  .head {
    padding: 11vw 0 13vw;
    background-size: auto 100%;
    > div {
      max-width: 36.4%;
      margin-left: 13%;
    }
  }
}

@include mixin.mediaTablet() {
  .head {
    background-position: 10% center;
    > div {
      max-width: 58%;
      margin-left: 3%;
    }
  }
}

@include mixin.mediaSmallTab() {
  .head {
    padding: 40px 0;
    h1 {
      font-size: 30px;
      span {
        font-size: 23px;
        line-height: 20px;
      }
    }
    ul {
      margin-bottom: 25px;
      li {
        font-size: 15px;
      }
    }
  }
}

@include mixin.mediaMobile() {
  .head {
    padding: 61vw 0 32.6vw;
    > div {
      margin: 0 auto;
      max-width: 76%;
    }
    img {
      object-position: center top;
    }
    h1 {
      text-align: center;
      font-size: 7.8vw;
      margin-bottom: 4vw;
      margin-top: 3vw;
      letter-spacing: 0;
      span {
        font-size: 4.6vw;
        > em > em {
          width: 10vw;
          height: 4vw;
        }
      }
    }
    ul {
      max-width: 85%;
      margin: 0 auto 4vw;
      li {
        font-size: 3.8vw;
        line-height: 4.3vw;
        padding: 0.7vw 0 2vw 25px;
        background-position: left 0.9vw;
        margin: 0;
        background-size: 16px auto;
      }
    }
    .action {
      text-align: center;
      margin-bottom: 15px;
    }
    .trustpilot {
      display: none;
    }
  }
}

@include mixin.mediaSmallMobile() {
  .head {
    a {
      width: 160px;
      line-height: 45px;
      font-size: 20px;
      padding: 0;
    }
  }
}
