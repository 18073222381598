@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.plats {
  padding: 45px 0;
  background-color: #ffffff;
  .slide {
    padding: 80px 20px 60px;
    position: relative;
    > div {
      transition: transform 0.2s;
      position: relative;
    }
    p {
      text-align: center;
      padding: 5px 10px;
      margin: 0;
      color: #191919;
      font-size: 1.1vw;
      line-height: 1.3vw;
      font-family: variable.$primaryFont;
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .feat {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
    &.noTooltip {
      &::after {
        display: none !important;
      }
    }
  }
}
@include mixin.mediaTablet() {
  .plats {
    .slide {
      padding: 20px 20px 40px;
      > div {
        transition: transform 0.2s;
        position: relative;
      }
      p {
        font-size: 1.6vw;
        line-height: 1.8vw;
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .plats {
    .slide {
      padding: 60px 20px 40px;
      > div {
        transition: transform 0.2s;
        position: relative;
      }
      p {
        visibility: hidden;
      }
    }
  }
}
