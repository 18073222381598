@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.full {
  width: 100%;
}
.h1 {
  font-family: variable.$entryTitleFont;
  color: variable.$whiteColor;
  font-size: 34px;
  line-height: 32px;
}
.h2 {
  text-align: center;
  font-family: variable.$entryTitleFont;
  color: variable.$blackColor;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 30px;
}
.h3 {
  text-align: center;
  font-family: variable.$secondaryEntryTitleFont;
  color: variable.$blackColor;
  font-size: 18px;
  line-height: 26px;
}
.h4 {
  text-align: center;
  font-family: variable.$defaultFont;
  color: variable.$blackColor;
  font-size: variable.$size16;
  line-height: normal;
  text-transform: uppercase;
}

@include mixin.mediaSmallTabletSize() {
  .h2 {
    font-size: 34px;
    line-height: 34px;
  }
  .h3 {
    font-size: 21px;
    line-height: 29px;
  }
}
@include mixin.mediaTabletSize() {
  .h2 {
    font-size: 34px;
    line-height: 34px;
  }
  .h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .h4 {
    font-size: variable.$size20;
  }
}
@include mixin.mediaDesktopSize() {
  .h1 {
    font-size: 54px;
    line-height: 54px;
  }
}
