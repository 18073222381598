@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.ecologie {
  padding: 55px 0 45px;
  border-top: 2px solid #ccc;
  .item {
    width: 50%;
    display: flex;
    padding: 22px 25px;
    box-sizing: border-box;
    position: relative;
    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 2px solid #e1e1e1;
    }
    &:nth-child(2),
    &:nth-child(4) {
      border-left: 2px solid #e1e1e1;
    }
    &:nth-child(4) {
      &::before {
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        position: absolute;
        left: -15px;
        top: -15px;
        border-radius: 25px;
      }
    }
    .feat {
      padding-right: 20px;
      width: 90px;
    }
    .label {
      font-family: variable.$titlesFont;
      display: block;
      text-align: center;
      color: variable.$primaryColor;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 5px;
      text-align: left;
    }
    .text {
      color: #191919;
      font-size: 17px;
      line-height: 21px;
      font-family: variable.$primaryFont;
      text-align: left;
    }
  }
}

@include mixin.mediaTablet() {
  .ecologie {
    .item {
      padding: 22px 15px;
      .label {
        font-size: 27px;
      }
      .text {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .ecologie {
    .item {
      .text {
        br {
          display: none;
        }
      }
    }
  }
}

@include mixin.mediaMobile() {
  .ecologie {
    padding: 35px 0 15px;
    .item {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;
      padding: 3vw 1vw;
      border: 0 !important;
      .feat {
        width: 19vw;
      }
      .label {
        font-size: 6vw;
      }
      .text {
        font-size: 3.5vw;
        line-height: 5vw;
      }
    }
  }
}

@include mixin.mediaMobile() {
  .ecologie {
    .item {
      .feat {
        width: 26vw;
      }
      .label {
        font-size: 7vw;
      }
      .text {
        font-size: 4vw;
        line-height: 5vw;
      }
    }
  }
}
