@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.banner {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  margin-bottom: -8px;
  img {
    width: 100%;
    height: auto;
  }
}
