@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.footer {
  padding: 20px 15px;
  border-top: 2px dotted #000;
  .item {
    width: 48.4%;
    padding: 20px 0;
    position: relative;
    .label {
      font-family: variable.$titlesFont;
      color: variable.$primaryColor;
      margin-bottom: 10px;
      text-align: left;
      font-size: 27px;
      line-height: 30px;
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
    }
    .text {
      color: #4b4b4b;
      font-size: 14px;
      line-height: 18px;
      font-family: variable.$primaryFont;
      text-align: left;
    }
    &::before {
      content: "";
      display: block;
      height: 94%;
      width: 2px;
      background-color: #ddf7da;
      position: absolute;
      left: -17px;
      top: 2%;
    }
    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 98%;
      position: absolute;
      bottom: 0;
      left: 1%;
      background-color: #ddf7da;
    }
    &:nth-child(2n + 1) {
      &::before {
        display: none;
      }
    }
    &:nth-last-child(-n + 2) {
      &::after {
        display: none;
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .footer {
    .item {
      .label {
        font-size: 24px;
        line-height: 27px;
        br {
          display: none;
        }
      }
    }
  }
}

@include mixin.mediaMobile() {
  .footer {
    padding: 0 15px 20px;
    .item {
      width: 100%;
      padding: 20px 0 0;
      &::after,
      &::before {
        display: none !important;
      }
    }
  }
}
