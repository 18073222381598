@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.item {
  background-color: variable.$lightWheat;
  color: variable.$blackColor;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 1px solid variable.$borderColor;
  &.active {
    .label {
      margin-bottom: 10px;
      &::after {
        content: "-";
        font-size: variable.$size28;
      }
    }
    .text {
      display: block;
    }
  }
  .label {
    font-family: variable.$entryTitleFont;
    font-size: variable.$size16;
    padding: 5px 40px 5px 10px;
    display: block;
    position: relative;

    &:after {
      content: "+";
      position: absolute;
      right: 15px;
      top: 50%;
      line-height: 15px;
      transform: translateY(-50%);
      font-family: variable.$interFont;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .text {
    border-top: 1px solid variable.$borderColor;
    padding: 10px;
    display: none;
    font-family: variable.$interFont;
    font-size: variable.$size14;
    line-height: 22px;
  }
}
