@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.themeWrapper {
  width: 100%;
  z-index: 1;
  margin-bottom: 15px;
  padding: 0 5px;
  order: -1;
  > div {
    height: 125px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: variable.$lightWheat;
    background-size: cover;
    background-position: center;
    .title {
      font-size: variable.$size14;
      font-family: variable.$interFont;
      font-style: italic;
      font-weight: 500;
      color: inherit;
    }
    .themeName {
      font-family: variable.$defaultFont;
      font-size: variable.$size36;
      text-transform: uppercase;
      position: relative;
      color: inherit;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -30px;
        width: 20px;
        height: 1px;
        background-color: currentColor;
        color: inherit;
      }
      &:after {
        left: auto;
        right: -30px;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .themeWrapper {
    order: -3;
  }
}
