@use "variables" as variable;

// Media query responsive
@mixin mediaDesktop() {
  @media (min-width: 1300px) {
    @content;
  }
}
@mixin mediaDesktopSize() {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin mediaTabletSize() {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin mediaSmallTabletSize() {
  @media (min-width: 630px) {
    @content;
  }
}
@mixin mediaSmallMobileSize() {
  @media (min-width: 481px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaTablet() {
  @media (max-width: 1023px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaSmallTab() {
  @media (max-width: 767px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaMobile() {
  @media (max-width: 629px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaSmallMobile() {
  @media (max-width: 480px) {
    @content;
  }
}

// Repetitif styles
@mixin coverAll {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@mixin fixedElement {
  position: fixed;
  @include coverAll;
}
@mixin bgBlack {
  background: rgba(0, 0, 0, 0.6);
}
@mixin bgCover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@mixin centerBox {
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
@mixin buttonPrimary {
  font-family: variable.$titlesFont;
  background-color: variable.$buttonColor;
  border: 1px solid transparent;
  line-height: 45px;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  letter-spacing: 1px;
  background-image: none;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  display: block;
  position: relative;
  &:disabled {
    opacity: 0.5;
  }
}
@mixin buttonDefault {
  font-family: variable.$defaultFont;
  background-color: variable.$orangeLightColor;
  border: 1px solid transparent;
  line-height: 50px;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  letter-spacing: 1px;
  background-image: none;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  display: block;
  position: relative;
  border: 1px solid variable.$orangeLightColor;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: variable.$whiteColor !important;
    color: variable.$orangeLightColor !important;
  }
}
@mixin buttonSecondary {
  font-family: variable.$titlesFont;
  font-size: 26px;
  color: #333333;
  display: inline-block;
  padding: 10px 35px 10px 20px;
  border: 2px solid #494746;
  border-radius: 5px;
  letter-spacing: 1px;
  position: relative;
  img {
    width: 17px;
    height: 13px;
    position: absolute;
    right: 8px;
    left: auto;
    top: 50%;
    margin-top: -4px;
  }
  &:hover {
    background-color: #f4f4f4;
  }
}

@mixin buttonSecondaryGreen {
  font-family: variable.$titlesFont;
  font-size: 26px;
  color: #76bf72;
  display: inline-block;
  padding: 10px 35px 10px 20px;
  border: 2px solid #76bf72;
  background-color: #ffffff;
  border-radius: 5px;
  letter-spacing: 1px;
  position: relative;
  img {
    width: 17px;
    height: 13px;
    position: absolute;
    right: 8px;
    left: auto;
    top: 50%;
    margin-top: -4px;
  }
  &:hover {
    background-color: #ffffff;
    color: variable.$buttonColorHover;
    border: 2px solid variable.$buttonColorHover;
  }
}
@mixin badge {
  background-color: variable.$primaryDarkerColor;
  padding: 0px 7px;
  border-radius: 3px;
  position: relative;
  transform: rotate(-5deg);
  display: inline-block;
  color: variable.$whiteColor;
  font-family: variable.$defaultFont;
  line-height: 20px;
  &::after,
  &::before {
    content: "";
    background-color: variable.$lightWheat;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: -4px;
    top: 50%;
    margin-top: -4px;
  }
  &::after {
    left: -4px;
  }
}
