@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.partners {
  padding: 40px 80px;
  margin-top: -10px;
  .item {
    width: 15%;
    .feat {
      height: 40px;
      margin: 0 auto 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 90%;
        width: auto;
        height: auto;
      }
    }
    p {
      color: #191919;
      font-size: variable.$size13;
      font-family: variable.$interFont;
      margin: 0;
    }
  }
}

@media (max-width: 1200px) {
  .partners {
    padding: 20px 10px;
  }
}

@include mixin.mediaTablet() {
  .partners {
    .item {
      width: 33%;
      margin-bottom: 25px;
    }
  }
}

@include mixin.mediaMobile() {
  .partners {
    .item {
      width: 48%;
    }
  }
}
