@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  min-height: calc(100vh - 59px);
  position: relative;
  display: flex;
  align-items: flex-end;
  .productOfYear {
    position: absolute;
    left: 20px;
    top: 45px;
    img {
      max-width: 90px;
    }
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgb(0 0 0 / 10%),
        rgb(0 0 0 / 75%)
      );
    }
    .video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      overflow: hidden;
      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
      video {
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .contentWrap {
    position: static;
    .content {
      padding: 0 20px;
      position: static;
      .subtitle {
        font-size: variable.$size24;
        font-family: variable.$entryTitleFont;
        color: variable.$whiteColor;
        margin-bottom: 26px;
        img {
          display: inline-block;
          height: 16px;
          vertical-align: baseline;
        }
      }
      .text {
        font-size: variable.$size18;
        line-height: 24px;
        font-family: variable.$interFont;
        color: variable.$whiteColor;
        ul {
          li {
            display: flex;
            color: variable.$whiteColor;
            margin-bottom: 10px;
            &:before {
              content: "";
              display: inline-block;
              min-width: 17px;
              height: 17px;
              background-image: url("/static/images/icons/check-circle-ulist.png");
              background-size: contain;
              background-repeat: no-repeat;
              margin: 3px 15px;
            }
          }
        }
      }
      .action {
        display: flex;
        justify-content: center;
        margin: 40px 0;
      }
    }
  }
  .tp {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #363636;
    white-space: nowrap;
    align-items: center;
    padding: 8px 10px;
    gap: 10px;
    width: 100%;
    justify-content: center;
    font-size: variable.$size14;
    color: variable.$whiteColor;
    > div {
      &:first-child {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          height: auto;
          min-width: 10px;
        }
      }
      &:nth-child(2) {
        min-width: 105px;
        max-width: 150px;
      }
      &:nth-child(3) {
        max-width: 110px;
      }
    }
  }
}
@include mixin.mediaSmallTabletSize() {
  .wrapper {
    min-height: calc(100vh - 93px);
    .productOfYear {
      display: none;
    }
  }
}
@include mixin.mediaTabletSize() {
  .wrapper {
    .bg {
      .video {
        width: 50%;
        left: auto;
        right: 0;
      }
      .poster {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background-size: cover;
        background-position: center;
        border-right: 2px solid variable.$whiteColor;
      }
    }
    .contentWrap {
      .content {
        .action {
          justify-content: flex-start;
        }
        .text {
          max-width: 365px;
        }
      }
    }
    .buttonTP {
      display: flex;
      margin-bottom: 30px;
    }
    .tp {
      position: static;
      background-color: transparent;
      width: auto;
      > div {
        &:first-child {
          img {
            display: none;
          }
        }
        &:nth-child(2) {
          max-width: 130px;
        }
        &:nth-child(3) {
          max-width: 105px;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    .contentWrap {
      .content {
        .subtitle {
          font-size: 30px;
          img {
            height: 20px;
          }
        }
        .text {
          max-width: none;
          ul {
            li {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}
