@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.kitchen {
  padding: 45px 0;
  background-color: #d6ecd4;
  position: relative;
  .mauro {
    background-color: #ffffff;
    border-radius: 20px;
    margin-bottom: 30px;
    position: relative;
    .feature {
      width: 31%;
      overflow: hidden;
      border-radius: 0 20px 20px 0;
      cursor: pointer;
      position: relative;
      span {
        display: block !important;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 15px;
        left: 15px;
        background-repeat: no-repeat;
        width: 70px;
        height: 70px;
        background-image: url("/static/images/icons/play_green.png");
        background-size: 100% auto;
      }
      img {
        width: 100%;
      }
    }
    .desc {
      width: 69%;
      display: flex;
      align-items: center;
      padding: 0 70px;
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -30px;
        left: -30px;
        background-repeat: no-repeat;
        width: 130px;
        height: 45px;
        background-image: url("/static/images/icons/image_flowers.png");
        background-size: 100% auto;
      }
      > div {
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: -30px;
          left: -30px;
          background-repeat: no-repeat;
          width: 30px;
          height: 23px;
          background-image: url("/static/images/icons/image_07.png");
          background-size: 100% auto;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -25px;
          right: -30px;
          background-repeat: no-repeat;
          width: 30px;
          height: 23px;
          background-image: url("/static/images/icons/image_14.png");
          background-size: 100% auto;
        }
      }
      .text {
        color: #333333;
        font-size: 17px;
        text-align: left;
        font-family: variable.$primaryFont;

        p {
          margin-bottom: 12px;
          &.name {
            text-align: right;
            font-style: italic;
          }
        }
      }
    }
  }
  .iframe > div > div {
    margin-top: 10%;
  }
}

.partners {
  padding: 20px 80px 0;
  margin-top: -10px;
  .item {
    width: 15%;
    .feat {
      margin: 0 auto 10px;
      max-width: 120px;
    }
    p {
      color: #151515;
      font-size: 13px;
      line-height: 17px;
      font-family: variable.$primaryFontBold;
      margin: 0;
    }
  }
}

@media (max-width: 1200px) {
  .partners {
    padding: 20px 10px 0;
  }
  .kitchen {
    .mauro {
      .desc {
        &::before {
          top: -23px;
          left: -13px;
          width: 110px;
          height: 38px;
        }
      }
    }
  }
}

@include mixin.mediaTablet() {
  .partners {
    .item {
      width: 33%;
      margin-bottom: 25px;
    }
  }
  .kitchen {
    .mauro {
      .feature {
        &::after {
          width: 60px;
          height: 60px;
        }
      }
      .desc {
        padding: 0 30px;
        .text {
          font-size: 16px;
        }
        &::before {
          top: -20px;
          left: -10px;
          width: 95px;
          height: 33px;
        }
        > div {
          &::before {
            top: -11px;
            left: -23px;
            width: 20px;
            height: 16px;
          }
          &::after {
            bottom: -11px;
            right: -23px;
            width: 20px;
            height: 16px;
          }
        }
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .kitchen {
    .mauro {
      .feature {
        width: 40%;
      }
      .desc {
        padding: 0 33px;
        .text {
          font-size: 14px;
          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@include mixin.mediaMobile() {
  .kitchen {
    .mauro {
      max-width: 95%;
      margin: 0 auto 30px;
      .feature {
        width: 100%;
        border-radius: 0 0 20px 20px;
        &::after {
          left: auto;
          right: 15px;
          top: 15px;
          width: 50px;
          height: 50px;
        }
      }
      .desc {
        width: 100%;
        padding: 12vw 10vw;
        .text {
          font-size: 4vw;
        }
      }
    }
  }
  .partners {
    .item {
      width: 48%;
      .feat {
        max-width: 160px;
      }
      p {
        font-size: 2.5vw;
        line-height: 3vw;
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .kitchen {
    .mauro {
      .desc {
        .text {
          font-size: 4.5vw;
        }
      }
    }
  }
  .partners {
    .item {
      .feat {
        max-width: 130px;
      }
      p {
        font-size: 3.5vw;
        line-height: 3.5vw;
      }
    }
  }
}
