@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  padding-top: 50px;
  border-top: 1px solid rgba(220, 207, 190, 0.5);
  border-bottom: 1px solid rgba(220, 207, 190, 0.5);
  padding-bottom: 30px;
  .title {
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    flex-direction: column-reverse;
    .column {
      padding: 0 10px;
      .img {
        img {
          border-radius: 5px;
        }
      }
    }
    .description {
      font-size: variable.$size14;
      color: variable.$blackColor;
      font-family: variable.$interFont;
      margin-top: 18px;
      text-align: center;
      margin-bottom: 20px;
      p {
        margin-bottom: 7px;
      }
      strong {
        font-weight: bold;
      }
      em {
        display: none;
        font-style: normal;
      }
    }
    .action {
      a {
        margin: auto;
      }
    }
    .quote {
      background-color: variable.$lightWheat;
      border-radius: 5px;
      padding: 18px 10px;
      margin-top: 30px;
      font-size: variable.$interFont;
      font-weight: 500;
      color: variable.$blackColor;
      text-align: center;
      font-size: variable.$size20;
      position: relative;
      &:after {
        content: "";
        border: 10px solid transparent;
        border-bottom-color: variable.$lightWheat;
        bottom: 100%;
        left: 50%;
        margin-left: -10px;
        position: absolute;
      }
      &:before {
        content: "";
        width: 25px;
        height: 20px;
        background-image: url("/static/images/icons/quote.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        left: 30px;
        top: -10px;
      }
      > p {
        &:after {
          content: "";
          width: 20px;
          height: 15px;
          display: inline-block;
          background-image: url("/static/images/icons/quote.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transform: scale(-1, 1);
          margin-left: 5px;
        }
      }
      strong {
        color: variable.$primaryDarkColor;
        font-weight: 700;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .wrapper {
    .title {
      text-align: left;
    }
    .row {
      flex-direction: row;
      .column {
        width: 50%;
      }
      .description {
        text-align: left;
        em {
          display: inline-block;
        }
      }
      .action {
        a {
          margin: 0;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    padding-top: 75px;
    padding-bottom: 25px;
    border-bottom: 1px solid variable.$lightWheat;
    .title {
      max-width: 300px;
      margin-bottom: 40px;
      margin-top: 45px;
    }
    .row {
      .column {
        position: relative;
      }
      .description {
        max-width: 410px;
        margin-bottom: 45px;
      }
      .quote {
        position: absolute;
        right: calc(100% - 80px);
        top: 60px;
        width: 320px;
        margin-top: 0;
        padding: 30px 30px;
        text-align: left;
        &::after {
          border-bottom-color: transparent;
          border-left-color: variable.$lightWheat;
          left: 100%;
          margin-left: 0;
          top: 50%;
          margin-top: -10px;
        }
      }
      .action {
        a {
          margin: 0;
        }
      }
    }
  }
}
