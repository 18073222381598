@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.head {
  position: relative;
  padding: 45px 0 30px;
}

@include mixin.mediaSmallTabletSize() {
  .head {
    padding: 45px 0;
  }
}

@include mixin.mediaDesktopSize() {
  .head {
    padding: 70px 0;
  }
}
