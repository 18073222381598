@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;
.container {
  padding-block: 50px;
}
.wrapper {
  padding-inline: 15px;
  .miniWidget {
    padding-left: 10px;
    max-width: max-content;
  }
  .sliderWidget {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}
.trustpilotWidget {
  padding: 30px;
  border: 1px solid #ccc;
  margin-top: 40px;
  border-radius: 10px;
  margin-inline: 15px;
}
.title {
  padding: 0 40px;
  margin-bottom: 10px;
}
.subtitle {
  margin-bottom: 15px;
  p {
    display: inline;
  }
  img {
    display: inline-block;
    vertical-align: middle;
  }
}
@include mixin.mediaTabletSize() {
  .wrapper {
    display: flex;
    align-items: center;
    .miniWidget {
      width: 250px;
      padding-bottom: 10px;
    }
    .sliderWidget {
      flex: 1;
      width: auto;
      margin: 0;
    }
  }
}
