@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.accordion {
  header {
    button {
      appearance: none;
      background-color: transparent;
      border: none;
      font-size: variable.$size18;
      color: variable.$blackColor;
      font-family: variable.$defaultFont;
      width: 100%;
      padding: 10px 15px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:after {
        content: "-";
        font-size: inherit;
        font-family: inherit;
        color: inherit;
      }
      &.collapsed {
        &::after {
          content: "+";
        }
      }
    }
  }
  section {
    font-size: variable.$size13;
    color: variable.$blackColor;
    font-family: variable.$interFont;
    border-bottom: 1px solid variable.$bgColor;
    > div {
      padding: 0px 15px 10px;
    }
  }
  .noDataFounded {
    padding: 15px;
    color: variable.$blackColor;
  }
}
@include mixin.mediaTabletSize() {
  .accordion {
    header {
      button {
        font-size: variable.$size22;
      }
    }
  }
}
