@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  p {
    font-family: variable.$interFont;
    color: variable.$blackColor;
    font-size: variable.$size14;
    margin-bottom: 6px;
    text-align: left;
    &.copyWith {
      font-family: variable.$interFont;
      font-weight: 600;
      font-size: variable.$size14;
    }
  }
  .content {
    flex: 1;
  }
  .icon {
    max-width: 35px;
  }
  .action {
    width: 100%;
    button {
      max-width: 350px;
      margin: 10px auto 0;
    }
  }
}
.message {
  color: variable.$blackColor;
  margin-top: 25px;
  text-align: center;
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    gap: 20px;
    .icon {
      width: 35px;
    }
    div {
      &:nth-child(2) {
        text-align: left;
      }
    }
    .action {
      margin: auto 0 0;
      width: auto;
      flex: 1;
    }
  }
}
