@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.video {
  padding-top: 20px;
  .top {
    padding-bottom: 40px;
    background-image: url("/static/images/bg/bgmv-top.jpg");
    background-repeat: no-repeat;
    background-position: 90% bottom;
  }

  .content {
    height: 380px;
    position: relative;
    span {
      display: block;
      @include mixin.coverAll;
      position: absolute;
      cursor: pointer;
      text-indent: -9999px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url("/static/images/icons/nv-btn.png");
        background-repeat: no-repeat;
        background-position: center;
        z-index: 2;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        z-index: 1;
        -webkit-transition: all 800ms;
        -o-transition: all 800ms;
        transition: all 800ms;
        opacity: 0;
      }
      &:hover {
        &::after {
          opacity: 0.4;
        }
      }
    }
  }
  .bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: url("/static/images/bg/bgmv-bottom.jpg");
    background-repeat: no-repeat;
    background-position: 5% top;
    p {
      font-family: variable.$primaryFont;
      color: #191919;
      font-size: 17px;
      line-height: 25px;
      text-align: center;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        background-image: url("/static/images/icons/mv-picto-movie.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
        width: 29px;
        height: 29px;
        vertical-align: bottom;
        margin-right: 15px;
      }
    }
  }
}

@include mixin.mediaTablet() {
  .video {
    .content {
      height: 330px;
      span {
        &::before {
          background-size: 100px;
        }
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .video {
    .bottom {
      p {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

@include mixin.mediaMobile() {
  .video {
    .top {
      background-image: none;
      padding-bottom: 10px;
    }
    .content {
      height: 390px;
      span {
        &::before {
          background-size: 75px;
        }
      }
    }
    .bottom {
      background-image: none;
      p {
        font-size: 13px;
        line-height: 13px;
        &:before {
          width: 22px;
          height: 22px;
          margin-right: 8px;
          vertical-align: top;
        }
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .video {
    .content {
      height: 280px;
    }
  }
}
