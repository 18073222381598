@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.bottom {
  padding: 70px 10px 0;
  background-color: variable.$primaryDarkerColor;
  color: variable.$whiteColor;

  .col {
    width: 20%;
    text-align: left;
    span {
      font-family: variable.$defaultFont;
      font-size: variable.$size20;
      margin-bottom: 12px;
      letter-spacing: 1px;
      display: block;
      text-align: left;
      text-transform: uppercase;
    }
    li {
      display: block;
      a {
        font-family: variable.$interFont;
        color: inherit;
        font-size: variable.$size14;
        margin-bottom: 10px;
        display: block;
        text-align: left;
      }
    }
    .reseaux {
      margin-right: 20px;
      li {
        display: inline-block;
        vertical-align: top;
        a {
          margin: 0 7px;
          img {
            display: inline-block;
            vertical-align: middle;
            height: 20px;
            width: auto;
          }
        }
      }
    }
  }
}
.bottomPart {
  border-top: 1px solid rgba($color: variable.$blackColor, $alpha: 0.5);
  margin-top: 140px;
  margin-inline: -10px;
  .last {
    .flex {
      padding-left: 20%;
      a,
      p {
        font-family: variable.$interFont;
        color: inherit;
        font-size: variable.$smallSize;
        line-height: 34px;
        text-decoration: none;
      }
      a {
        margin-left: 22px;
      }
    }
  }
}

@include mixin.mediaTablet() {
  .bottom {
    padding: 50px 10px 0;
    .flex {
      flex-wrap: wrap;
    }
    .col {
      width: 25%;
      &:first-child {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }
  .bottomPart {
    .last {
      .flex {
        padding-inline: 20px;
      }
    }
  }
}
@include mixin.mediaSmallTab() {
  .bottom {
    .flex {
      gap: 10px;
    }
    .col {
      width: calc(25% - 7.5px);
      &.collogo {
        width: 100%;
      }
    }
  }
}
@include mixin.mediaMobile() {
  .bottom {
    .flex {
      gap: 20px;
      padding: 0;
    }
    .col {
      width: calc(50% - 10px);
      &.collogo {
        width: 100%;
        img {
          margin: auto;
        }
      }
    }
  }
  .bottomPart {
    .last {
      .flex {
        padding-inline: 10px;
      }
    }
  }
}
