@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.blog {
  padding: 50px 0;
  background-color: #daf5d9;
  .item {
    width: 25%;
    > div {
      background-color: #fff;
      margin: 0 10px;
      padding-bottom: 25px;
    }
    .label {
      font-family: variable.$primaryFont;
      color: #000;
      display: block;
      padding: 14px 0 0;
      position: relative;
      font-size: 17px;
      &::after {
        content: "";
        display: block;
        width: 45px;
        height: 2px;
        background-color: #000000;
        margin: 15px auto;
      }
    }
    .text {
      font-family: variable.$primaryFont;
      color: #141414;
      font-size: 16px;
      text-align: center;
      line-height: 19px;
      min-height: 70px;
      margin: 0 10px;
    }
    a {
      font-size: 20px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  .btnBottom {
    text-align: center;
    margin-top: 35px;
    a {
      text-transform: uppercase;
    }
  }
}

@include mixin.mediaTablet() {
  .blog {
    .item {
      > div {
        margin: 0 5px;
      }
      .label {
        font-size: 16px;
      }
      .text {
        font-size: 15px;
        line-height: 17px;
        min-height: 68px;
      }
      a {
        padding: 10px 30px 10px 10px;
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .blog {
    .item {
      width: 50%;
      margin-bottom: 15px;
      .label {
        font-size: 15px;
        line-height: 17px;
      }
    }
  }
}

@include mixin.mediaMobile() {
  .blog {
    .item {
      width: 100%;
      margin: 20px auto;
      max-width: 380px;
      .label {
        font-size: 17px;
        line-height: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .btnBottom {
      a {
        font-size: 17px;
      }
    }
  }
}
