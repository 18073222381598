@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  background-color: variable.$lightWheat;
  padding: 35px 0 20px;
  .title {
    margin-bottom: 3px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .subtitle {
    margin-bottom: 35px;
  }
  > div {
    max-width: 1124px;
  }
  .steps {
    max-width: 680px;
    margin: 0 auto;
    .step {
      background-color: variable.$whiteColor;
      border: 1px solid variable.$borderColor;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 40px;
      position: relative;
      .top {
        text-align: center;
        background-color: variable.$primarylightColor;
        padding: 13px 20px;
        min-height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          display: block;
          font-family: variable.$entryTitleFont;
          font-size: variable.$size24;
          color: variable.$blackColor;
        }
        > p {
          color: variable.$primaryColor;
          font-family: variable.$primaryFont;
          font-size: 17px;
          margin-bottom: 12px;
        }
      }
      .feature {
        margin-bottom: 20px;
      }
      .h3Title {
        font-size: variable.$size16;
        font-weight: 600;
      }
      .boxParrainage {
        background-color: variable.$whiteColor;
        border-radius: 8px;
        overflow: hidden;
        padding: 10px 10px 0;
        margin-bottom: 20px;
        .image {
          margin: 25px auto 35px;
        }

        .title {
          margin-block: 20px 25px;
          width: 100%;
        }
        > div {
          align-items: center;
          gap: 20px;
        }
        .col {
          width: calc(50% - 10px);
          height: 180px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          position: relative;
          height: 145px;
          border-radius: 10px;
          background-color: variable.$lightWheat;
          .label {
            display: block;
            font-family: variable.$entryTitleFont;
            font-size: variable.$size18;
            color: variable.$primaryDarkerColor;
          }
          .price {
            display: block;
            font-family: variable.$defaultFont;
            font-size: 44px;
            line-height: 44px;
            color: variable.$primaryDarkerColor;
          }
          .text {
            font-family: variable.$interFont;
            font-size: variable.$smallSize;
            color: variable.$blackColor;
            sup {
              font-size: 10px;
            }
          }
          &.first {
            background-color: variable.$primaryDarkerColor;
            .text,
            .label,
            .price {
              color: #fff;
            }
          }
        }
      }
      .action {
        text-align: center;
        a,
        Button {
          display: inline-block;
          width: auto;
          font-size: 21px;
        }
      }
      &.discoverCustomer {
        .content {
          padding: 20px 20px 35px;
        }
        &.discoverCustomer {
          .h3Title {
            margin-bottom: 15px;
          }
        }
        .feature {
          img {
            margin: auto;
          }
        }
      }
    }
  }
}

@include mixin.mediaTablet() {
  .root {
    .steps {
      .step {
        .top {
          > span {
            font-size: variable.$size18;
            line-height: 20px;
            strong {
              left: 0px;
              top: 4px;
            }
          }
          > p {
            font-size: 15px;
            padding-left: 25px;
          }
        }
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .root {
    .steps {
      .step {
        .action {
          a,
          Button {
            font-size: 19px;
          }
        }
      }
    }
  }
}

@include mixin.mediaDesktopSize() {
  .root {
    .steps {
      .step {
        .top {
          > span {
            br {
              display: none;
            }
          }
        }
        .h3Title {
          font-size: 20px;
        }
        .boxParrainage {
          padding: 25px 50px 30px;
        }
      }
    }
  }
}
