@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  margin-bottom: 35px;
  .title {
    font-family: variable.$secondaryFont;
    font-size: variable.$size26;
    color: variable.$textColor;
    text-align: center;
    margin-bottom: 15px;
  }
  .text {
    color: variable.$textColor;
    font-size: variable.$size16;
    line-height: variable.$size19;
    font-family: variable.$primaryFont;
    text-align: justify;
    margin-bottom: 0;
    ul {
      margin-bottom: 25px;
      margin-top: 25px;
    }
    li {
      margin-bottom: 5px;
      list-style: none;
      padding-left: 15px;
      &::before {
        content: "\2022";
        color: variable.$primaryColor;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
    strong {
      font-family: variable.$primaryFontBold;
    }
  }
}
