@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  padding-top: 70px;
  border-bottom: 1px solid rgba(220, 207, 190, 0.5);
  padding-bottom: 25px;
  margin-bottom: 50px;
  .articles {
    width: 100%;
    [class*="slick-slide"] {
      visibility: visible;
    }
    .article {
      padding-inline: 20px;
      img {
        width: 100%;
        border-radius: 5px;
      }
      .title {
        font-size: variable.$size14;
        color: variable.$blackColor;
        font-family: variable.$interFont;
        font-weight: bold;
        margin-block: 10px 4px;
        display: block;
        line-height: 18px;
      }
      .excerpt {
        line-height: 18px;
        font-size: variable.$size13;
        color: variable.$blackColor;
        font-family: variable.$interFont;
        margin-bottom: 5px;
      }
      .link {
        line-height: 18px;
        font-size: variable.$size13;
        color: variable.$blackColor;
        text-decoration: underline;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .wrapper {
    padding-bottom: 40px;
    .articles {
      .article {
        padding-inline: 30px;
      }
    }
  }
}
