@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  border-block: 1px solid rgba(220, 207, 190, 0.5);
  + section {
    border-top: none;
    margin-top: 0;
  }
}
