@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.manger {
  padding: 20px 0 30px;
  margin: 20px 0 0;
  position: relative;

  .content {
    background-position: center top;
    background-repeat: no-repeat;
  }
  .hashtag {
    background-color: variable.$primaryDarkerColor;
    max-width: max-content;
    padding: 6px 10px 6px 31px;
    text-transform: uppercase;
    border-radius: 5px;
    margin: 0 auto 30px;
    transform: rotate(-3deg) scale(1.2);
    font-family: "Vinyl OT";
    letter-spacing: 0.4px;
    position: relative;
    &:before {
      content: "#";
      font-size: 47px;
      position: absolute;
      left: 2px;
      top: -10px;
      transform: rotate(7deg);
    }
  }
  .head {
    font-family: variable.$defaultFont;
    letter-spacing: 1px;
    font-size: 34px;
    color: #333333;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 35px;
    br {
      display: none;
    }
  }
  .item {
    background-color: variable.$lightWheat;
    padding: 115px 25px 25px;
    position: relative;
    max-width: 290px;
    border-radius: 25px;
    margin-bottom: 30px;
    text-align: right;
    .icon {
      position: absolute;
      right: 25px;
      top: 30px;
      width: 120px;
      height: 75px;
      img {
        margin-right: 0;
        margin-left: auto;
      }
    }
    .label {
      display: block;
      font-family: variable.$defaultFont;
      letter-spacing: 1px;
      color: #333333;
      font-size: 30px;
      margin-bottom: 10px;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 102%;
        top: 0;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .text {
      margin: 0;
      color: #333333;
      font-size: 17px;
      line-height: 21px;
      font-family: variable.$interFont;
    }
    &:nth-child(2n) {
      text-align: left;
      .icon {
        left: 25px;
        img {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
    &:nth-child(1) {
      .label {
        &::after {
          background-image: url("/static/images/bg/img_picto_13.png");
          width: 229px;
          height: 151px;
          top: 15px;
        }
      }
    }
    &:nth-child(2) {
      .label {
        &::after {
          background-image: url("/static/images/bg/img_picto_10.png");
          width: 204px;
          height: 153px;
          top: 9px;
          left: auto;
          right: 104%;
        }
      }
    }
    &:nth-child(3) {
      .label {
        &::after {
          background-image: url("/static/images/bg/img_picto_25.png");
          width: 205px;
          height: 125px;
          bottom: 7px;
          top: auto;
        }
      }
    }
    &:nth-child(4) {
      .label {
        &::after {
          background-image: url("/static/images/bg/img_picto_23.png");
          width: 196px;
          height: 92px;
          bottom: 35px;
          top: auto;
          left: auto;
          right: 104%;
        }
      }
    }
  }
}

@include mixin.mediaTablet() {
  .manger {
    .content {
      max-width: 738px;
      margin: 0 auto;
      background-size: 700px auto;
    }
    .item {
      padding: 100px 20px 25px;
      max-width: 225px;
      width: 100%;
      .icon {
        top: 20px;
      }
      .label {
        font-size: 27px;
      }
      .text {
        font-size: 15px;
        line-height: 19px;
      }
      &:nth-child(1) {
        .label {
          &::after {
            width: 174px;
            height: 118px;
            background-position: left top;
          }
        }
      }
      &:nth-child(2) {
        .label {
          &::after {
            width: 149px;
            height: 115px;
            background-size: 100% auto;
            background-position: top;
          }
        }
      }
      &:nth-child(3) {
        .label {
          &::after {
            width: 150px;
            height: 95px;
            background-size: 100% auto;
            background-position: left bottom;
          }
        }
      }
      &:nth-child(4) {
        .label {
          &::after {
            width: 141px;
            height: 79px;
            background-size: 100% auto;
          }
        }
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .manger {
    .content {
      background-size: 100%;
      background-position: top calc(50% - 30px) center;
      > div {
        gap: 320px 20px;
      }
      .flex {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .item {
      padding: 100px 10px 25px;
      max-width: 236px;
      width: 47.5%;
      position: relative;
      margin: auto auto 0;
      .icon {
        img {
          height: 80%;
          width: auto;
        }
      }
      &:first-child {
        .label {
          &::after {
            top: calc(100% - 20px);
            left: calc(100% - 20px);
            transform: rotate(57.5deg);
            transform-origin: top left;
          }
        }
      }
      &:nth-child(2) {
        .label {
          &::after {
            top: calc(100% - 20px);
            right: calc(100% - 20px);
            transform: rotate(-54.5deg);
            transform-origin: top right;
          }
        }
      }
      &:last-child {
        margin: 0 auto auto;
        .label {
          &::after {
            bottom: calc(100% - 27px);
            top: auto;
            right: calc(100% - 8px);
            transform: rotate(68deg);
            transform-origin: bottom right;
          }
        }
      }
      &:nth-child(3) {
        margin: 0 auto auto;
        .label {
          &::after {
            bottom: calc(100% - 20px);
            top: auto;
            left: calc(100% - 20px);
            transform: rotate(-60deg);
            transform-origin: bottom left;
          }
        }
      }
      .icon {
        top: 20px;
      }
      .label {
        font-size: 27px;
        position: static;
        &::after {
          background-size: contain;
          width: 79px !important;
          height: 63px !important;
        }
      }
      .text {
        font-size: 15px;
        line-height: 19px;
        br {
          display: none;
        }
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .manger {
    .head {
      font-size: 28px;
      margin-bottom: 15px;
      br {
        display: block;
      }
    }
    .content {
      .flex {
        gap: 250px 0;
      }
    }
    .item {
      max-width: 45%;
    }
  }
}
