@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.slider {
  background-color: #daf5d9;
  padding: 55px 0 45px;
  .slide {
    padding: 80px 20px 60px;
    position: relative;
    > div {
      transition: transform 0.2s;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 60px;
        height: 60px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url("/static/images/icons/visuel_plats_p1.png");
        position: absolute;
        left: 50%;
        margin-left: -30px;
        bottom: -30px;
      }
    }
    p {
      background-color: #ffffff;
      text-align: center;
      padding: 5px 10px;
      margin: 0;
      color: #191919;
      font-size: 16px;
      line-height: 20px;
      font-family: variable.$primaryFont;
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .feat {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}

@include mixin.mediaSmallTab() {
  .slider {
    .slide {
      padding: 60px 20px 40px;
      > div {
        transition: transform 0.2s;
        position: relative;
        &::after {
          width: 40px;
          height: 40px;
          margin-left: -20px;
          bottom: -20px;
          background-size: 36px auto;
        }
      }
    }
  }
}
