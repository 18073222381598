@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.parrainage {
  .item {
    width: 350px;
    margin: 0 30px;
    .feature {
      width: 100%;
      margin-bottom: 15px;
    }
    .price {
      text-align: center;
      color: #000000;
      font-size: 38px;
      font-family: variable.$titlesFont;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 38px;
    }
    .label {
      text-align: center;
      color: variable.$primaryColor;
      font-size: 31px;
      font-family: variable.$titlesFont;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .text {
      font-family: variable.$primaryFont;
      text-align: center;
      color: #333333;
      font-size: 17px;
      line-height: 22px;
    }
  }
  .bottom {
    font-family: variable.$primaryFont;
    text-align: center;
    color: #333333;
    font-size: 17px;
    line-height: 22px;
    margin-top: 30px;
  }
}

@include mixin.mediaTablet() {
  .parrainage {
    .item {
      width: 40%;
    }
  }
}

@include mixin.mediaSmallTab() {
  .parrainage {
    .item {
      margin: 0 25px;
      .price {
        font-size: 35px;
        line-height: 33px;
      }
      .label {
        font-size: 28px;
        margin-bottom: 10px;
      }
      .text {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .bottom {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@include mixin.mediaMobile() {
  .parrainage {
    .item {
      margin: 0 10px;
      width: 44%;
      br {
        display: none;
      }
    }
  }
}

@include mixin.mediaSmallMobile() {
  .parrainage {
    .item {
      margin: 30px auto 0;
      width: 300px;
      max-width: 88%;
    }
  }
}
