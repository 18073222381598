@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  border-top: 1px solid variable.$fadePrimaryColor;
  padding-top: 40px;
  margin-top: 20px;
  padding-inline: 15px;
  padding-bottom: 30px;
  .content {
    .items {
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
      .item {
        border-radius: 10px;
        border: 2px solid variable.$primaryDarkerColor;
        width: min(465px, 100%);
        padding-bottom: 30px;
        &:nth-child(2) {
          border-color: variable.$primarylightColor;
          .title {
            color: variable.$primarylightColor;
          }
          hr {
            border-color: variable.$primarylightColor;
          }
        }
        .img {
          position: relative;
          padding-top: 64%;
          overflow: hidden;
          img {
            position: absolute;
            inset: 0;
            object-fit: cover;
            border-radius: 7px 7px 0 0;
          }
        }
        hr {
          width: min(320px, 80%);
          border: none;
          border-bottom: 1px solid variable.$primaryDarkerColor;
          margin-block: 20px;
        }
        .title {
          color: variable.$primaryDarkerColor;
          text-align: center;
          font-family: variable.$defaultFont;
          font-size: variable.$size36;
          font-weight: 400;
          line-height: 26px;
          text-transform: uppercase;
          margin-top: 30px;
          &::first-letter {
            text-transform: uppercase;
          }
        }
        .text {
          color: #131212;
          text-align: center;
          font-family: variable.$interFont;
          font-size: variable.$size16;
          font-weight: 400;
          line-height: 20px;
          strong,
          b {
            font-weight: 500;
            em {
              display: inline-block;
            }
          }
        }
      }
    }
    .action {
      padding-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
}
@include mixin.mediaTabletSize() {
  .wrapper {
    .entryTitle {
      br {
        display: none;
      }
    }
  }
}
